<template>
  <v-container
    id="accounts"
    fluid
    tag="section"
  >
    <v-data-table
      :headers="headers"
      :items="accounts"
      :items-per-page="10"
      class="elevation-1"
      item-key="id"
      :search="filterText"
      :loading="loadingAccounts"
      loading-text="Carregando contas..."
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Contas</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            class="mt-8"
            label="Filtrar"
            v-model="filterText"
            solo
            append-icon="mdi-magnify"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            @click="newAccount"
          >
            Nova conta
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.active="{ item }">
        <v-chip v-if="item.active"
          color="success"
          dark
        >
          Ativo
        </v-chip>
        <v-chip v-else
          color="error"
          dark
        >
          Inativo
        </v-chip>
      </template>
      <template v-slot:item.modified="{ item }">
        {{ getFormattedDate(item.modified) }}
      </template>
      <template v-slot:item.expire_date="{ item }">
        {{ getFormattedDate(item.expire_date) }}
      </template>
      <template v-slot:item.detail.city="{ item }">
        {{ item.detail.city && item.detail.city.length > 0 ? `${item.detail.city} - ${item.detail.state}` : '' }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          color="primary"
          fab
          x-small
          @click="editAccount(item)"
        >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          color="error"
          fab
          x-small
          @click="confirmDeleteAccount(item)"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- confirmar exclusão  -->
    <v-dialog
      v-model="confirmDelete"
      max-width="500"
    >
      <v-card>
        <v-card-title class="headline">
          Excluir conta
        </v-card-title>

        <v-card-text>
          Deseja realmente excluir a conta {{ selectedAccount.id }} - {{ selectedAccount.account }}?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDelete = false"
          >
            Não
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="deleteAccount"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Excluido com sucesso -->
    <v-dialog
      v-model="successDelete"
      max-width="350"
    >
      <v-card>
        <v-card-title class="headline">
          Conta excluída.
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="successDelete = false"
          >
            Ok
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Progresso excluindo -->
    <v-dialog
      persistent
      v-model="progressDelete"
      max-width="350"
    >
      <v-card>
        <v-progress-linear
          :indeterminate="true"
          height="30"
          :active="true"
          color="deep-purple darken-4"
        >
          <v-subheader
            class="white--text display-1"
          >
            Excluindo conta...
          </v-subheader>
        </v-progress-linear>
      </v-card>
    </v-dialog>

    <!-- formulario de cadastro -->
    <v-dialog
      v-model="formDialog2"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="formDialog2 = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ editingAccount.id > 0 ? "Editar conta" : "Nova conta" }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn class="green" style="padding: 5px 25px !important"
              dark
              text
              @click="saveAccount"
            >
              Salvar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-list
          three-line
          subheader
        >
          <v-subheader>Dados</v-subheader>
          <v-row no-gutters class="mx-4">
            <v-col cols="12" sm="3" class="px-2">
              <v-text-field
                label="Conta"
                v-model="editingAccount.account"
              />
            </v-col>
            <v-col cols="12" sm="3" class="px-2">
              <v-text-field
                label="Licença"
                v-model="editingAccount.license"
              />
            </v-col>
            <v-col cols="12" md="3" class="px-2">
              <v-select
                color="secondary"
                item-color="secondary"
                label="Selecione o grupo"
                :items="groups"
                v-model="editingAccount.parent_id"
                item-text="account"
                item-value="id"
                :disabled="!hasAdminPermission"
              >
              </v-select>
            </v-col>
            <v-col
              cols="12"
              sm="2" class="px-2"
            >
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="editingAccount.expire_date"
                    label="Data validade"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editingAccount.expire_date"
                  no-title
                  @input="menu1 = false"
                  :show-current="true"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              sm="1" class="px-2"
            >
              <v-switch
                :false-value="0"
                :true-value="1"
                label="Ativo?"
                v-model="editingAccount.active"
                class="mx-2"
              />
            </v-col>
          </v-row>
        </v-list>
        <v-divider></v-divider>

        <v-subheader>Linx</v-subheader>
        <v-row no-gutters class="mx-4">
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Portal"
                v-model="editingAccount.linx_portal"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="CNPJ"
                v-model="editingAccount.linx_cnpj"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Id da empresa"
                v-model="editingAccount.linx_empresaid"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Id da impressora"
                v-model="editingAccount.printnode_id"
              />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-subheader>Cadastro empresa</v-subheader>
        <v-row no-gutters class="mx-4">
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="CNPJ"
                v-model="editingAccount.detail.cnpj"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Inscrição estadual"
                v-model="editingAccount.detail.ie"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Nome da empresa"
                v-model="editingAccount.detail.company_name"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Razão social"
                v-model="editingAccount.detail.razao_social"
              />
          </v-col>
        </v-row>
        <v-row no-gutters class="mx-4">
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Email administrador"
                v-model="editingAccount.detail.email_adm"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Telefone"
                v-model="editingAccount.detail.phone_number1"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Telefone 2"
                v-model="editingAccount.detail.phone_number2"
              />
          </v-col>
        </v-row>
        <v-row no-gutters class="mx-4">
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Endereço"
                v-model="editingAccount.detail.address"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Bairro"
                v-model="editingAccount.detail.district"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Cidade"
                v-model="editingAccount.detail.city"
              />
          </v-col>
          <v-col cols="12" sm="1" class="px-2">
            <v-select
                color="secondary"
                item-color="secondary"
                label="Estado"
                :items="statesList"
                v-model="editingAccount.detail.state"
                item-text="name"
                item-value="id"
              >
            </v-select>
          </v-col>
          <v-col cols="12" sm="1" class="px-2">
            <v-text-field
                label="País"
                v-model="editingAccount.detail.country"
              />
          </v-col>
        </v-row>
        
        <v-divider></v-divider>
        <v-subheader>Dados do responsável</v-subheader>
        <v-row no-gutters class="mx-4">
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Nome"
                v-model="editingAccount.detail.name_rep"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="CPF"
                v-model="editingAccount.detail.cpf_rep"
              />
          </v-col>
          <v-col cols="12" sm="3" class="px-2">
            <v-text-field
                label="Email"
                v-model="editingAccount.detail.email_rep"
              />
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import axios from 'axios';
import { baseApiUrl } from '@/global';
import moment from 'moment';
import { states } from '@/code/util/constants';
import localStorageUtil from '@/code/util/localStorageUtil';
import { AuthUtil } from '@/code/util/authUtil';
import { EUserRole } from '@/code/enums/EUserRole';
import { EventBus } from '@/code/util/eventBus';

axios.defaults.headers.common['Content-Type'] = 'application/json';

export default {

  data() {
    return {
      accounts: [],
      selectedAccount: { detail: {} },
      editingAccount: { detail: {} },
      groups: [],
      confirmDelete: false,
      successDelete: false,
      progressDelete: false,
      filterText: "",
      formDialog2: false,
      loadingAccounts: false,
      statesList: states,
      hasAdminPermission: Boolean,
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: true,
          value: 'id',
          filterable: false
        },
        {
          text: 'Descrição',
          align: 'start',
          sortable: true,
          value: 'account',
        },
        {
          text: 'Cidade',
          align: 'start',
          sortable: true,
          value: 'detail.city'
        },
        {
          text: 'Grupo',
          align: 'start',
          sortable: true,
          value: 'group.account',
          filterable: false
        },
        {
          text: 'Data de expiração',
          align: 'start',
          sortable: true,
          value: 'expire_date',
          filterable: false
        },
        {
          text: 'Ativo?',
          align: 'start',
          sortable: true,
          value: 'active',
          filterable: false
        },
        {
          text: 'Data modificação',
          align: 'start',
          sortable: true,
          value: 'modified',
          filterable: false
        },
        { text: 'Ações', value: 'actions', sortable: false }
      ]
    }
  },
  mounted() {
    this.updatePermissions();
    EventBus.$on("currentUserLoaded", this.updatePermissions);
    this.loadAccounts();
  },

  methods: {
    loadAccounts: async function() {
      this.loadingAccounts = true;
      this.accounts = [];
      var accounts = await axios.get(`${baseApiUrl}accounts?all=1&detail=1&type=account`);
      if (accounts) {
        accounts.data.data.forEach(account => {
          this.accounts.push(this.createNewAccount(account));
        });
      }
      this.loadingAccounts = false;
    },
    loadGroups: async function() {
      this.groups = [];
      if (this.hasAdminPermission) {
        if (!this.groups || this.groups.length === 0) {
          this.groups = [];
          var groups = await axios.get(`${baseApiUrl}accounts?all=1&detail=0&type=group`);
          if (groups) {
            groups.data.data.forEach(group => {
              if (group.active === 1) {
                this.groups.push({ id: group.id, account: group.account });
              }
            });
          }
        }
      } else {
        this.groups = [];
        const group = localStorageUtil.getCurrentUserGroup();
        this.groups.push({ id: group.id, account: group.account });
        this.editingAccount.parent_id = group.id;
      }
    },
    createNewAccount: function(account) {
      var ac = {
        id: account.id,
        account: account.account,
        license: account.license,
        active: account.active,
        parent_id: account.parent_id,
        expire_date: account.expire_date,
        created: account.created,
        modified: account.modified,
        linx_portal: account.linx_portal,
        linx_cnpj: account.linx_cnpj,
        linx_empresaid: account.linx_empresaid,
        printnode_id: account.printnode_id
      };

      ac.group = {};
      if (account.group) {
        ac.group = {
            id: account.group.id,
            account: account.group.account,
            license: account.group.license
        };
      }

      ac.detail = {};
      if (account.detail) {
        ac.detail = {
          id: account.detail.id,
          id_accounts: account.detail.id_accounts,
          cnpj: account.detail.cnpj,
          ie: account.detail.ie,
          company_name: account.detail.company_name,
          razao_social: account.detail.razao_social,
          name_rep: account.detail.name_rep,
          cpf_rep: account.detail.cpf_rep,
          email_rep: account.detail.email_rep,
          email_adm: account.detail.email_adm,
          phone_number1: account.detail.phone_number1,
          phone_number2: account.detail.phone_number2,
          address: account.detail.address,
          district: account.detail.district,
          city: account.detail.city,
          state: account.detail.state,
          country: account.detail.country,
          layout: account.detail.layout,
          modified: account.detail.modified,
          created: account.detail.created,
        };
      }

      return ac;
    },
    getFormattedDate: function(date) {
      return date ? moment.utc(date).format('DD/MM/YYYY') : '';
    },
    getFormattedDateTime: function(date) {
      return date ? moment.utc(date).format('DD/MM/YYYY HH:mm:ss') : '';
    },
    newAccount: function() {
      this.editingAccount = this.createNewAccount({ id: 0, active: 1 });
      this.loadGroups();
      this.formDialog2 = true;
    },
    editAccount: function(account) {
      this.editingAccount = this.createNewAccount(account);
      this.loadGroups();
      this.formDialog2 = true;
    },
    confirmDeleteAccount: function(account) {
      this.selectedAccount = account;
      this.confirmDelete = true;
    },
    deleteAccount: async function() {
      if (this.selectedAccount) {
        this.confirmDelete = false;
        this.progressDelete = true;
        var response = await axios.delete(`${baseApiUrl}accounts/${this.selectedAccount.id}`);
        this.progressDelete = false;
        if (response && response.status >= 200 && response.status < 300) {
          this.accounts.splice(this.accounts.indexOf(this.selectedAccount), 1);
          this.selectedAccount = this.createNewAccount({});
          this.successDelete = true;
        }
      }
    },
    saveAccount: async function() {
      if (this.editingAccount) {
        var toSave = {
          account: this.editingAccount.account,
          license: this.editingAccount.license,
          active: this.editingAccount.active,
          parent_id: this.editingAccount.parent_id,
          expire_date: this.editingAccount.expire_date,
          linx_portal: this.editingAccount.linx_portal,
          linx_cnpj: this.editingAccount.linx_cnpj,
          linx_empresaid: this.editingAccount.linx_empresaid,
          printnode_id: this.editingAccount.printnode_id,
          type: 'account',
          detail: {
            cnpj: this.editingAccount.detail.cnpj,
            ie: this.editingAccount.detail.ie,
            company_name: this.editingAccount.detail.company_name,
            razao_social: this.editingAccount.detail.razao_social,
            name_rep: this.editingAccount.detail.name_rep,
            cpf_rep: this.editingAccount.detail.cpf_rep,
            email_rep: this.editingAccount.detail.email_rep,
            email_adm: this.editingAccount.detail.email_adm,
            phone_number1: this.editingAccount.detail.phone_number1,
            phone_number2: this.editingAccount.detail.phone_number2,
            address: this.editingAccount.detail.address,
            district: this.editingAccount.detail.district,
            city: this.editingAccount.detail.city,
            state: this.editingAccount.detail.state,
            country: this.editingAccount.detail.country
          }
        }
        this.editingAccount.type = 'account';
        var response = null;
        if (this.editingAccount.id > 0) {
          response = await axios.put(`${baseApiUrl}accounts/${this.editingAccount.id}`, toSave);
        } else {
          response = await axios.post(`${baseApiUrl}accounts`, toSave);
        }

        if (response && response.status >= 200 && response.status < 300) {
          if (this.editingAccount.id > 0) {
            var that = this;
            var itemsById = this.accounts.filter((account) => account.id === that.editingAccount.id);
            if (itemsById && itemsById.length > 0) {
              this.accounts.splice(this.accounts.indexOf(itemsById[0]), 1);
            }
          }
          //response.data.data.group = this.groups.filter((group) => group.id === response.data.data.parent_id)[0];
          this.accounts.push(this.createNewAccount(response.data.data));
          this.formDialog2 = false;
        }
      }
    },
    updatePermissions: function() {
      const authUtil = new AuthUtil();
      const rule = authUtil.getUserRole();
      this.hasAdminPermission = rule === EUserRole.Admin;
    },
  }
}
</script>
